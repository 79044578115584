<template>
  <div class="informed_consent" v-wechat-title="title">
    <div id="content"></div>
    <div class="btn">
      <div class="agree-box">
        <van-checkbox v-model="checked" :disabled="checkDisabled" icon-size="16px">{{tips_text}}</van-checkbox>
      </div>
      <van-button round type="info" size="large" class="up" :disabled="checked?btnDisabled:true" @click="next">
        {{btnText}}
      </van-button>
    </div>
    <div class="goBack" @click="back">
      <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import {
  Toast
} from "vant";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css"
import {
  getSubProjectAgreement,
  signInformedConsent,
} from '@/api/api'
import {
  appHost
} from '@/utils/host'
import {parseUrl} from '@/utils/utils'
import { mapState } from 'vuex';
export default {
  data() {
    return {
      baseFileUrl: appHost,
      pdfh5: null,
      checked: false,
      checkDisabled: true,
      btnDisabled: false,
      btnText: '',
      short_name: '',
      tips_text: '',
      title: '',
    }
  },
  computed: {
    ...mapState(['doctorInfo','userId', 'basicInformation'])
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.short_name = this.basicInformation.projects[0].short_name
      if(this.short_name == 'xhzl') {
        this.tips_text = '我已阅读并告知患者该知情同意书'
        this.title = '患者知情同意书'
      }else {
        this.tips_text = '我已阅读并同意该知情同意书'
        this.title = '知情同意书'
      }
      let {
        items
      } = await getSubProjectAgreement()
      if(this.short_name == 'xhzl') {
        this.btnText = '下一步'
        this.pdfh5 = new Pdfh5("#content", {
          pdfurl: parseUrl(items[0].patient_informed_consent, this.baseFileUrl)
        });
      }else {
        this.btnText = '提交审核'
        this.pdfh5 = new Pdfh5("#content", {
          pdfurl: parseUrl(items[0].informed_consent, this.baseFileUrl)
        });
      }
      
      //监听完成事件
      this.pdfh5.on("complete", function (status, msg, time) {
        console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
      })
      this.checkDisabled = false
    },
    async next() {
      if(this.short_name == 'xhzl') {
        // 消化肿瘤2.0下一步是走病例流程
        this.$router.push({
          path: '/case',
          query: {
            scheme_id: this.$route.query.scheme_id,
            subscheme_id: this.$route.query.subscheme_id,
            phase: this.$route.query.phase,
            enable: this.$route.query.enable,
          }
        })
      }else {
        await signInformedConsent({
          project_doctor_id: this.$route.query.project_doctor_id
        }).then(res => {
          if(res) {
            console.log("回到资质认证成功页面")
            history.go(-1)
          }
        })
      }
    },
    back() {
      history.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.informed_consent{
  // font-size: 16px;
  .agree-box {
    line-height: 20px;
    padding: 10px;
    text-align: center !important;
    vertical-align: middle;
      font-size: 14px;
    .van-checkbox {
      justify-content: center;
    }
  }
}
</style>